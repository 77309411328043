import {
    RiFacebookCircleFill,
    RiLinkedinBoxFill,
    RiDiscordFill,
    RiDownloadLine,
    RiYoutubeFill,
} from 'react-icons/ri';
import RoutesJSON from '../../json/routes.json';
import ContactsJSON from '../../json/contacts.json';
import { Link } from 'react-router-dom';
import { generateZip } from '../../tools/ZipGenerator';
import { NavHashLink } from 'react-router-hash-link';

const columns = [
    {
        title: 'À propos',
        elements: [
            {
                name: 'Informations générales',
                link: RoutesJSON.about,
            },
            {
                name: 'Jeux',
                link: RoutesJSON.games,
            },
        ],
    },
    {
        title: 'Participation',
        elements: [
            {
                name: 'Inscris-toi (fermé)',
                link: '',
            },
        ],
    },
    {
        title: 'Aide',
        elements: [
            {
                name: 'Contact',
                link: RoutesJSON.contact,
            },
            {
                name: 'Règlement',
                link: RoutesJSON.rules,
            },
        ],
    },
];

const Footer = () => (
    <footer className={'xl:mx-120 mx-16 mb-4 mt-20'}>
        <div
            className={
                'flex flex-wrap xl:gap-0 gap-x-4 gap-y-8 justify-between mb-4'
            }
        >
            {columns.map((column, columnIndex) => {
                return (
                    <Column key={columnIndex}>
                        <h3 className={'text-base xl:text-lg'}>
                            {column.title}
                        </h3>
                        {column.elements.map((element, index) => (
                            <NavHashLink
                                smooth
                                to={`${
                                    element.link.startsWith('/') ||
                                    element.link.startsWith('http')
                                        ? ''
                                        : '#'
                                }${element.link}`}
                                className={'hover:text-blue'}
                                key={index}
                            >
                                {element.name}
                            </NavHashLink>
                        ))}
                    </Column>
                );
            })}
            <Column>
                <h3 className={'text-base xl:text-lg inline'}>Ressources</h3>
                <button
                    className={
                        'text-left inline-flex items-center hover:text-blue'
                    }
                    onClick={generateZip}
                >
                    Presskit <RiDownloadLine className={'ml-2'} />
                </button>
            </Column>
        </div>
        <hr />
        <div
            className={'flex justify-between items-center text-sm my-4 gap-y-3'}
        >
            <div>© {new Date().getFullYear()} LVLUP Game Jam</div>
            <Social />
        </div>
        <div className={'text-xs'}>
            * La plus grande game jam de 48h en présentiel de Suisse.
        </div>
    </footer>
);

const Column = ({ children }) => (
    <div className={'flex flex-col gap-y-2'}>{children}</div>
);

const Social = () => (
    <div className={'flex gap-2 text-xl'}>
        <Link
            to={ContactsJSON.discord}
            target={'_blank'}
            className={'hover:text-blue'}
        >
            <RiDiscordFill size={25} />
        </Link>
        <Link
            to={ContactsJSON.youtube}
            target={'_blank'}
            className={'hover:text-blue'}
        >
            <RiYoutubeFill size={25} />
        </Link>
        <Link
            to={ContactsJSON.facebook}
            target={'_blank'}
            className={'hover:text-blue'}
        >
            <RiFacebookCircleFill size={25} />
        </Link>
        <Link
            to={ContactsJSON.linkedin}
            target={'_blank'}
            className={'hover:text-blue'}
        >
            <RiLinkedinBoxFill size={25} />
        </Link>
    </div>
);
export default Footer;
