import './background.css';
import editionJSON from '../../json/edition.json';
import { useState } from 'react';
import CustomButton from '../CustomButton';
import { useNavigate } from 'react-router-dom';
import RoutesJSON from '../../json/routes.json';
const { startDay, endDay, month, year } = editionJSON.date;
const { edition } = editionJSON;

const Hero = ({ sponsors }) => {
    const navigate = useNavigate();
    return (
        <div
            className={'flex flex-col'}
            style={{ height: 'calc(100vh - 5rem)' }}
        >
            <div className="flex flex-grow grid-container bg-black">
                <div className="plane">
                    <div className="grid" />
                </div>
                <div
                    className={
                        'flex flex-grow justify-center text-center items-center text-blue'
                    }
                >
                    <div className={'flex flex-col'}>
                        <p
                            className={
                                'text-7xl xl:text-9xl font-powerr title-top select-none'
                            }
                        >
                            LVLUP {edition}
                        </p>
                        <div className="mb-6 -mt-2">
                            <CustomButton
                                textSize={'3xl'}
                                onClick={() => {
                                    //window.open('', '_blank');
                                    // navigate(RoutesJSON.login);
                                }}
                            >
                                <span className="uppercase font-bold">
                                    Inscris-toi (fermé)
                                </span>
                            </CustomButton>
                        </div>
                        <p
                            className={
                                'text-5xl xl:text-7xl font-bold font-poppins title-bottom select-none'
                            }
                        >
                            {formatDate(startDay, endDay, month, year)}
                        </p>
                    </div>
                </div>
                <div className="plane">
                    <div className="grid"></div>
                </div>
            </div>

            {sponsors}
        </div>
    );
};

export const Sponsors = ({ sponsors }) => (
    <div className={'items-center h-full flex flex-col m-5'}>
        <h3 className={'text-lg xl:text-xl mb-5'}>Soutenu par</h3>
        <div
            className={
                'flex-col flex xl:flex-row items-center gap-x-10 gap-y-5'
            }
        >
            {sponsors.map((sponsor, index) => (
                <HoverImage
                    key={index}
                    hoverSrc={`${sponsor[0]}.svg`}
                    defaultSrc={`${sponsor[0]}-black.svg`}
                    alt={sponsor[1]}
                />
            ))}
        </div>
    </div>
);

const HoverImage = ({ hoverSrc, defaultSrc, alt }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <img
            className={'h-12 hover:cursor-pointer'}
            src={isHovered ? hoverSrc : defaultSrc}
            alt={alt}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => window.open(`https://${alt}`, '_blank')}
        />
    );
};

const formatDate = (startDay, endDay, month, year) =>
    `${startDay}-${endDay}.${month.toUpperCase()}.${year}`;

export default Hero;
